.impressum {
  padding: 50px 7%;
}

.impressum-heading {
  font-size: 35px;
  font-weight: bold;
}

.fixed-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
}
