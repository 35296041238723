.my-events-page {
  padding: 40px 7%;
  /* background-color: #f6f6f8; */
  background: linear-gradient(180deg, var(--bg-light-color) 0%, #e8e8e8 100%);
  min-height: 100vh;
}

.page-title {
  font-weight: 600;
  font-size: 24px;
}

.tabActive,
.tabInactive {
  cursor: pointer;
  text-align: center;
  color: gray;
  font-weight: 400;
  padding: 10px;
  transition: 0.2s ease-in-out;
  font-size: 16px;
}

.tabActive {
  border-bottom: 3px solid var(--primary-color);
}

.tabInactive {
  border-bottom: 3px solid rgb(227, 227, 227);
}

.event-tab {
  cursor: pointer;
  text-align: center;
  color: gray;
  font-weight: 400;
  padding: 10px;
  transition: 0.2s ease-in-out;
  font-size: 16px;
  border-bottom: 3px solid rgb(227, 227, 227);
}

.event-tab.active {
  border-bottom: 3px solid var(--primary-color);
}

.event-numbers {
  background-color: var(--primary-color);
  border-radius: 50%;
  width: 23px;
  height: 23px;
  font-size: 12px;
  border: none;
  color: white;
  font-weight: 600;
}

.add-new-event {
  display: flex;
  flex-direction: row;
  border: 1px dashed silver;
  padding: 20px;
  background-color: #f3f3f3;
  align-items: center;
  margin-top: 30px;
  border-radius: 15px;
  color: gray;
}

.add-icon {
  height: 60px;
  width: 60px;
  background-color: #e3e3e3;
  border-radius: 50%;
  line-height: 55px;
}

.event-carousel {
  margin-top: 40px;
}

.event-card {
  padding: 15px 15px 40px 15px;
  border-radius: 15px 15px 0 0;
  background-color: transparent;
  color: gray;
  cursor: pointer;
  position: relative;
}

.event-sub-card {
  border-radius: 15px;
  box-shadow: 0 0px 5px rgba(0, 0, 0, 0.06);
  background: #fff;
  color: gray;
  cursor: pointer;
  padding: 20px;
  border: 1px solid #fff;
}

.event-card.active {
  background-color: #fff;
  box-shadow: 0 0px 5px rgba(0, 0, 0, 0.03);
}

.event-card.active::before {
  content: "";
  position: absolute;
  background-color: transparent;
  left: -30px;
  bottom: 13px;
  height: 15px;
  width: 30px;
  border-bottom-right-radius: 15px;
  box-shadow: 15px 0 0 0 #fff;
}

.event-card.active::after {
  content: "";
  position: absolute;
  background-color: transparent;
  right: -30px;
  bottom: 13px;
  height: 15px;
  width: 30px;
  border-bottom-left-radius: 15px;
  box-shadow: -15px 0 0 0 #fff;
  /* overflow: auto; */
  z-index: 3;
}

.event-sub-card.active {
  background-color: #f6f6f8;
  border: 1px solid #e9e9e9;
  box-shadow: none;
}

.event-flexbox {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 15px;
}

.event-card-city {
  color: #757294;
  font-size: 16px;
  font-weight: 700;
}

.event-price {
  color: var(--Dark-Dark, #111928);
  font-size: 18px;
  font-weight: 700;
}

.event-price-per-person {
  color: #637381;
  font-size: 14px;
}

.event-card-text {
  color: #757294;
  font-size: 14px;
  font-weight: 500;
}

.completed-bar {
  background-color: var(--primary-color) !important;
}

.denied-bar {
  background-color: #ff4444 !important;
}

.events-service {
  border-radius: 15px;
  background: #fff;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.06);
  padding: 25px;
  padding-bottom: 35px;
  margin-top: -14px;
  text-align: center;
}

.timeline {
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 10px 10px 60px 0px rgba(0, 0, 0, 0.06);
  text-align: center;
}

.timeline-btn {
  color: var(--primary-color);
  font-weight: bold;
  cursor: pointer;
  padding: 20px;
  transition: "0.5s";
}

.services-carousel {
  margin-top: 10px;
}

.event-supplier-card {
  /* cursor: pointer; */
  border-radius: 15px;
  padding: 0 10px;
  position: relative;
}

.event-supplier-image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 15px;
  height: 285px;
  position: relative;
}

.declined-service-img {
  background-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.54) 0%,
      rgba(0, 0, 0, 0.54) 100%
    ),
    url("../../assests/images/service_img3.jpeg");
}

.confirm-event-card {
  background-color: #1c1c1c;
  padding: 25px;
  border-radius: 15px;
}

.confirm-event-price {
  font-size: 28px;
  font-weight: bold;
  margin: 10px 0;
  color: white;
}

.confirm-event-pricing {
  justify-content: space-between;
  background-color: var(--bg-light-color);
  padding: 5px 10px;
  border-radius: 10px;
}

.event-supplier-header {
  /* font-size: 14px; */
  color: white;
  font-weight: 600;
  /* background: rgb(255, 255, 255, 0.25);
  border-bottom: 1px solid rgb(233, 231, 231, 0.1); */
  padding: 5px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 15px 20px 0px 0px;
  background: rgba(255, 255, 255, 0.12);
  box-shadow: 10px 10px 20px 0px rgba(0, 0, 0, 0.06);
  backdrop-filter: blur(2px);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.event-supplier-tags {
  text-align: left;
  font-size: 13px;
}

.event-supplier-image-body {
  padding: 10px;
}

.event-supplier-OFFERED-tag,
.event-supplier-PENDING-tag,
.event-supplier-ACCEPTED-tag,
.event-supplier-DECLINED-tag,
.event-supplier-DELETED-tag,
.event-supplier-CANCELLED-tag,
.event-supplier-SUCCESSED-tag,
.event-supplier-PAID-tag {
  border-radius: 15px;
  color: white;
  padding: 5px 8px;
  border: none;
  display: inline-block;
  width: auto;
}

.event-supplier-DECLINED-tag,
.event-supplier-DELETED-tag,
.event-supplier-CANCELLED-tag {
  background-color: #f46060;
}
.event-supplier-ACCEPTED-tag,
.event-supplier-SUCCESSED-tag {
  background-color: var(--primary-color);
}
.event-supplier-PAID-tag {
  background-color: #49dd83;
}
.event-supplier-PENDING-tag {
  background-color: #ff9e58;
}
.event-supplier-OFFERED-tag {
  background-color: #4db7c0;
}

.event-supplier-chat-icon,
.event-supplier-info-icon {
  width: 45px;
  height: 45px;
  /* background: rgb(255, 255, 255, 0.25);
  border: 1px solid rgb(233, 231, 231, 0.1); */
  border-radius: 50%;
  position: absolute;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: rgba(255, 255, 255, 0.12);
  box-shadow: 10px 10px 20px 0px rgba(0, 0, 0, 0.06);
  backdrop-filter: blur(2px);
}

.event-supplier-chat-icon {
  left: 12px;
  bottom: 12px;
}

.event-supplier-info-icon {
  right: 12px;
  bottom: 12px;
}

.paynow-btn {
  position: absolute;
  bottom: 15px;
  transform: translateX(-50%);
  height: 40px;
  background-color: var(--primary-color);
  padding: 0 10px;
  border-radius: 10px;
  border: none;
  color: white;
  font-weight: 500;
  font-size: 14px;
}

.paynow-btn:hover {
  background-color: var(--primary-hover-color);
}

.paynow-btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.send-offer-btn {
  background-color: #4db7c0 !important;
  border: 1px solid #4db7c0 !important;
  padding: 10px 30px !important;
  border-radius: 8px !important;
  font-weight: 600 !important;
}

.send-offer-btn:hover {
  background-color: 007bff !important;
  border: 1px solid 007bff !important;
}

.accept-offer-btn {
  background-color: var(--primary-color) !important;
  border: 1px solid var(--primary-color) !important;
  padding: 10px 30px !important;
  border-radius: 8px !important;
  font-weight: 600 !important;
}

.accept-offer-btn:hover {
  background-color: var(--primary-hover-color) !important;
  border: 1px solid var(--primary-hover-color) !important;
}

.delete-supplier-btn {
  color: #e52020 !important;
  background-color: transparent !important;
  border: 1px solid #e52020 !important;
  padding: 10px 30px !important;
  border-radius: 8px !important;
  font-weight: 600 !important;
}

.delete-supplier-btn:hover {
  background-color: #e52020 !important;
  border: 1px solid #e52020 !important;
  color: white !important;
}

.offer-modal {
  max-width: 86% !important;
  margin: 40px auto !important;
  border: none !important;
}

.modal-card {
  /* padding: 20px; */
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 10px 10px 60px 0px rgba(0, 0, 0, 0.06);
}

.modal-card-header {
  padding: 15px 25px;
  font-size: 18px;
  font-weight: 700;
  border-bottom: 0.5px solid #ebebeb;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.modal-card-body {
  padding: 25px;
}

.team-event-div {
  background-color: var(--primary-bg-color);
  padding: 8px 10px;
  border-radius: 10px;
  font-size: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.team-event-icon {
  width: 34px;
  height: 34px;
  border: none;
  color: white;
  background-color: var(--primary-color);
  border-radius: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.mh-padding {
  padding: 20px 30px !important;
}

.table-div {
}

.table-style {
  text-align: center;
  border-radius: 12px !important;
  overflow: hidden !important;
  border-color: #eeeef0 !important;
}

.table-header {
  background-color: #ebebf2 !important;
  color: #757294 !important;
  font-size: 13px;
  font-weight: 600;
}

.table-body {
  background-color: var(--bg-light-color) !important;
  font-size: 13px;
}

.detailed-modal-flex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: gray;
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 14px;
}

.supplier-chat-box {
  /* height: 100%; */
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 10px 10px 60px 0px rgba(0, 0, 0, 0.06);
  display: flex;
  flex-direction: column;
}

.supplier-chat-box.bot {
  height: 550px;
}

.supplier-chat-box.supplier {
  height: 670px;
}

.chat-header {
  border-bottom: 1px solid #ebebeb;
  flex: 0.06;
  padding: 20px 25px;
}

.chat-main-heading {
  font-size: 16px;
  font-weight: 500;
}

.chat-header-flex {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
}

.chat-sub-heading {
  color: #637381;
  font-size: 14px;
  font-weight: 400;
}

.chat-body {
  flex: 0.88;
  /* height: 100%; */
  padding: 15px 25px;
  /* display: flex;
  flex-direction: column;
  justify-content: end; */
  overflow-y: auto;
}

.chat-footer {
  border-top: 1px solid #ebebeb;
  flex: 0.06;
  padding: 20px 25px;
}

.chat-supplier-img {
  height: 55px;
  width: 55px;
  border-radius: 50%;
}

.chat-message-flex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.chat-message-box {
  background-color: #f6f6f8 !important;
  margin-right: 10px;
  align-items: center;
}

.chat-message-input {
  border: none;
  background-color: transparent;
  width: 100%;
  height: 35px;
}

.supplier .chat-message-input {
  margin-right: 10px;
}

.chat-message-input:focus {
  outline: none;
}

.message-send-btn {
  background-color: var(--primary-color);
  color: white;
  border: none;
  width: 55px;
  border-radius: 5px;
}

.user-msg-flex {
  display: flex;
  flex-direction: row;
  justify-content: end;
}

.supplier-msg-flex {
  display: flex;
  flex-direction: row;
  justify-content: start;
}

.user-message {
  background-color: var(--primary-color);
  color: white;
  padding: 10px;
  border-radius: 10px 10px 0 10px;
  /* marg */
}

.supplier-message {
  background-color: #f6f6f8;
  padding: 10px;
  color: gray;
  border-radius: 0 10px 10px 10px;
  margin-bottom: 8px;
}

.user-msg-time {
  margin-bottom: 15px;
}

.supplier-msg-time {
  margin-bottom: 15px;
}

.user-message-time {
  text-align: right;
  color: gray;
  font-size: 0.7rem;
  margin-top: 5px;
}

.supplier-message-time {
  text-align: left;
  color: gray;
  font-size: 0.7rem;
}

.supplier-msg-name {
  color: gray;
  font-weight: 600;
  margin-bottom: 10px;
}

.events-progress {
  color: #757294;
  font-size: 0.9rem;
  font-weight: 600;
  margin-left: 10px;
}

.service-name-tagline {
  color: rgba(0, 6, 33, 0.34);
}

.table-body {
  vertical-align: middle;
}

.myevent-supplier-heading-text {
  padding: 0 5px;
  color: #1c1c1c;
  font-size: 16px;
  font-weight: 700;
}

.request-now-btn {
  margin: 35px auto 10px;
  border-radius: 10px;
  padding: 10px;
  background-color: var(--primary-color);
  color: white;
  font-weight: 700;
  cursor: pointer;
  text-align: center;
  font-size: 13px;
  width: 150px;
}

.request-now-btn:hover {
  background-color: var(--primary-hover-color);
}

.remove-service-btn {
  margin: auto;
  border: 1.5px solid #f46060;
  border-radius: 10px;
  padding: 10px;
  color: #f46060;
  font-weight: 700;
  cursor: pointer;
  text-align: center;
  font-size: 13px;
  width: 150px;
}

.remove-service-btn:hover {
  background-color: #f46060;
  color: white;
}

.event-carousel .owl-nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* position: absolute */
}

.event-carousel .owl-next,
.event-carousel .owl-prev {
  font-size: 2.5rem !important;
  color: var(--primary-color) !important;
  background-color: white !important;
  box-shadow: 10px 10px 60px 0px rgba(0, 0, 0, 0.06) !important;
  width: 45px !important;
  height: 45px !important;
  line-height: 45px !important;
  border-radius: 50% !important;
}

.event-carousel .owl-nav span {
  position: relative;
  bottom: 5px !important;
}

.event-carousel .owl-next {
  right: -60px;
  position: relative;
  bottom: 230px;
}

.event-carousel .owl-prev {
  left: -60px;
  position: relative;
  bottom: 230px;
}

.loading-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 90vh;
}

.text-centered {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #e52020;
  font-weight: bold;
  /* height: 50vh; */
  font-size: 25px;
  text-transform: capitalize;
}

.event-supplier-card .supplier-text {
  padding: 10px 0;
}
