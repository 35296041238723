.event-box {
  padding: 20px;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0 0px 15px rgba(0, 0, 0, 0.1);
}

.create-event-labels {
  font-size: 0.9rem;
  color: gray;
}

.create-event-input,
.create-event-datetime,
.create-event-time {
  background-color: var(--bg-light-color) !important;
  box-shadow: none !important;
  font-size: 0.8rem !important;
  height: 40px;
  border-color: #e9e9e9 !important;
  width: 100%;
  border-radius: 10px !important;
}

.mui-input {
  border-radius: 10px !important;
  background-color: var(--bg-light-color);
  height: 40px !important;
  font-size: 0.8rem !important;
  color: #1c1c1c !important;
}

@media screen and (min-width: 1300px) {
  .create-event-labels {
    font-size: 0.8rem;
  }

  .create-event-input,
  .create-event-datetime,
  .create-event-time,
  .mui-input {
    font-size: 0.8rem !important;
  }
}

.time1,
.time2 {
  font-size: 0.8rem !important;
  flex: 1;
}

.vertical-line {
  height: 25px;
  width: 1px;
  background-color: #e9e9e9;
  margin: 0px;
}

.create-event-input {
  border-left: none !important;
}

.create-event-input:focus {
  /* border-color: #dee2e6 !important; */
}

.input-group-text {
  margin-right: 0 !important;
  padding-right: 0 !important;
  border-color: #bcbcc4 !important;
}

/* .long-label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
} */

.datePosition {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 2px;
  /* width: 100%; */
}

.dropdown-menu {
  width: 50px !important;
}

.date-input::-webkit-calendar-picker-indicator {
  /* position: absolute; */
  font-size: 14px;
}

.date-input::-webkit-datetime-edit {
  /* position: relative;
  left: 15px; */
  display: none;
}

.date-input::-webkit-datetime-edit-fields-wrapper {
  /* position: relative;
  left: 15px;  */
  display: none;
}

.date-input {
  border: none;
  background-color: transparent;
  margin-right: 7px;
}

.date-input:focus {
  outline: none;
}

.mui-select-div {
  border: 1px solid #e9e9e9;
  border-radius: 10px;
}

.euro-sign {
  /* font-weight: 600; */
  color: black;
  font-size: 15px;
}
