nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  padding: 12px 7%;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.06);
}

.website-logo {
  width: 40px;
}

.logoname {
  font-size: 30px;
  font-weight: bold;
  text-decoration: none;
  color: black;
}

.loginBtn {
  margin-left: 15px;
  color: var(--primary-color);
  padding: 10px 20px;
  border-radius: 10px;
  border: 1px solid var(--primary-color);
  font-size: 16px;
  font-weight: 600;
  background: transparent;
}

.signupBtn {
  margin-left: 15px;
  background-color: var(--primary-color);
  color: #fff;
  padding: 10px 20px;
  border-radius: 10px;
  border: 1px solid var(--primary-color);
  font-size: 16px;
  font-weight: 600;
  text-decoration: none;
}

@media screen and (max-width: 600px) {
  .website-logo {
    width: 30px;
  }

  .logoname {
    font-size: 20px;
  }

  .loginBtn,
  .signupBtn {
    padding: 7px 10px;
    font-size: 14px;
  }

  .signupBtn {
    margin-left: 10px;
  }

  .loginBtn {
    margin-left: 10px;
  }
}

.loginBtn:hover {
  background-color: var(--primary-color);
  color: #fff;
}

.signupBtn:hover {
  background-color: var(--primary-hover-color);
}

#navbar {
  display: flex;
  align-items: center;
  justify-content: center;
}

#navbar li {
  list-style: none;
  padding: 0 10px;
  position: relative;
  top: 7px;
}

#navbar li a {
  text-decoration: none;
  font-size: 1rem;
  font-weight: 600;
  border: 1px solid var(--primary-color);
  padding: 8px 10px;
  border-radius: 5px;
  /* transition: 0.3s ease-in-out; */
}

/* #navbar li a:hover,
  #navbar li a.active {
    color: #17cf97;
  }
  
  #navbar li a:hover::after,
  #navbar li a.active::after {
    content: "";
    width: 30%;
    height: 2px;
    background: #17cf97;
    position: absolute;
    bottom: -4px;
    left: 20px;
  } */

#mobile {
  display: none;
}

#mobile i {
  color: black;
  align-items: center;
}

@media screen and (max-width: 768px) {
  #navbar {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: fixed;
    top: 70px;
    right: -300px;
    width: 200px;
    height: 100vh;
    background: #fff;
    box-shadow: 0 40px 60px rgba(0, 0, 0, 0.1);
    padding: 40px 0 0 10px;
    transition: 0.3s ease-in-out;
  }

  #navbar.active {
    right: 0px;
  }

  #navbar li {
    margin-bottom: 25px;
  }

  #mobile {
    display: block;
  }

  #mobile i {
    font-size: 24px;
    cursor: pointer;
  }
}

.navbar-profile-div,
.right-div {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.navbar-profile-div {
  font-weight: 600;
  cursor: pointer;
}

.navbar-profile-img {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.nav-dropdown-item {
  color: black !important;
}

@media screen and (max-width: 769px) {
  .navbar-profile-text,
  .navbar-icon {
    display: none;
  }
}

.switch-link {
  color: black;
  text-decoration: none;
  font-weight: 500;
}

.switch-link:hover {
  text-decoration: underline;
}
