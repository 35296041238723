.supplier-info {
  padding: 35px 7%;
  background-color: #f6f6f8;
  min-height: 100vh;
}

.supplier-header {
  justify-content: space-between;
}

.supplier-name {
  font-size: 1.3rem;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
}

.share-btn {
  border: 1px solid var(--primary-color);
  border-radius: 8px;
  padding: 8px 10px;
  color: var(--primary-color);
  font-weight: bold;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}

.share-btn:hover {
  background-color: var(--primary-color);
  color: white;
}

@media screen and (max-width: 450px) {
  .supplier-name {
    gap: 10px;
    font-size: 18px;
  }

  .share-btn {
    gap: 3px;
    padding: 6px;
    font-size: 14px;
  }
}

.supplier-body {
  margin-top: 40px;
}

.info-cards {
  border-radius: 20px;
  background: #fff;
  box-shadow: 10px 10px 60px 0px rgba(0, 0, 0, 0.06);
  padding: 20px 25px;
}

.card-icon {
  background-color: var(--primary-color);
  height: 52px;
  width: 52px;
  border-radius: 50%;
  display: flex;

  justify-content: center;
  align-items: center;
}

.card-info-alignment {
  align-items: center;
}

.card-tagline {
  margin-top: 10px;
  color: #848484;
}

.card-heading {
  font-weight: bold;
  font-size: 1.1rem;
}

.card-pricing {
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
}

.per-person-price {
  font-weight: bold;
  font-size: 1.8rem;
}

.per-person {
  color: #848484;
  margin-top: -8px;
}

.left-headings {
  color: #848484;
}

.border1 {
  border-bottom: 0.5px solid #ebebeb;
  margin: 20px 0;
}

.total-price {
  font-size: 1.4rem;
  font-weight: bold;
}

.add-to-event-btn {
  margin-top: 25px;
  border-radius: 10px;
  padding: 15px;
  background-color: var(--primary-color);
  color: white;
  font-weight: bold;
  cursor: pointer;
  text-align: center;
}

.add-to-event-btn:hover {
  background-color: var(--primary-hover-color);
}

.request-offer-btn {
  margin-top: 15px;
  border: 1.5px solid var(--primary-color);
  border-radius: 10px;
  padding: 15px;
  color: var(--primary-color);
  font-weight: bold;
  cursor: pointer;
  text-align: center;
}

.request-offer-btn:hover {
  background-color: var(--primary-color);
  color: white;
}

.description-box {
  border-radius: 20px;
  background: white;
  box-shadow: 10px 10px 60px 0px rgba(0, 0, 0, 0.06);
}

.height-adjustment {
  height: 100%;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
}

.box-header {
  padding: 24px 36px;
  font-size: 1.2rem;
  font-weight: bold;
  border-bottom: 1px solid #ebebeb;
}

.other-products-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.other-product-img {
  width: 110px;
  height: 110px;
  border-radius: 20px;
  object-fit: cover;
}

.box-desc {
  padding: 24px 36px;
  color: #848484;
}

.product-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
  gap: 20px;
}

.product-txt {
  font-weight: 700;
  color: #848484;
  font-size: 16px;
}

.product-sub-txt {
  /* font-weight: 700; */
  color: #848484;
  font-size: 14px;
}

.flex-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.img-class {
  border-radius: 20px;
}

.fwd-icon {
  line-height: 250px;
  text-align: center;
}

.video,
.img1,
.img2,
.img3 {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 20px;
  height: 250px;
  /* cursor: pointer; */
  position: relative;
}

.video {
  /* line-height: 250px; */
  text-align: center;
  height: 400px;
}

@media screen and (max-width: 600px) {
  .video {
    height: 250px;
  }
}

.enlarger {
  position: absolute;
  bottom: 15px;
  right: 15px;
  cursor: pointer;
}

/* .img1 {
  background-image: url("../../assests/images/img1_full.png");
}

.img2 {
  background-image: url("../../assests/images/img2_full.jpeg");
}

.img3 {
  background-image: url("../../assests/images/img3_full.jpeg");
} */

.service-event-info-flex {
  display: flex;
  flex-direction: row;
  gap: 15px;
  margin-bottom: 10px;
}

.service-info-card-txt1 {
  color: #848484;
  font-size: 16px;
  font-weight: 700;
}

.service-info-card-txt2 {
  color: #848484;
  font-size: 16px;
  font-weight: 500;
}

.service-info-tags-div {
  margin-top: 20px;
  /* display: flex;
  flex-direction: row; */
  gap: 10px;
}

.service-info-tags {
  display: inline-block;
  border-radius: 50px;
  border: 1px solid var(--primary-color);
  background: var(--primary-bg-color);
  letter-spacing: 2px;
  text-transform: uppercase;
  color: var(--primary-color);
  text-align: center;
  font-size: 10px;
  /* width: 100%; */
  padding: 4px 8px;
  font-weight: 500;
  margin-bottom: 6px;
  margin-right: 6px;
}
