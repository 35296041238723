.page-title {
  font-weight: 600;
  font-size: 24px;
}

.profile-settings-page {
  padding: 40px 7%;
  background-color: #f6f6f8;
  min-height: 100vh;
}

.profile-settings-div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
  /* cursor: pointer; */
  position: relative;
}

.edit-photo-btn {
  background-color: white;
  box-shadow: 10px 10px 60px 0px rgba(0, 0, 0, 0.06);
  border: none;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  position: absolute;
  bottom: 0;
  right: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.profile-settings-img {
  height: 70px;
  width: 70px;
  border-radius: 50%;
  object-fit: cover;
}

.profile-settings-card {
  border-radius: 20px;
  background: #fff;
  box-shadow: 10px 10px 60px 0px rgba(0, 0, 0, 0.06);
  padding: 25px;
}

.profile-card-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.profile-card-name {
  font-size: 18px;
  font-weight: 700;
  cursor: pointer;
}

.profile-card-edit-btn {
  border: 1.5px solid var(--primary-color);
  border-radius: 8px;
  padding: 9px 15px;
  color: var(--primary-color);
  font-weight: bold;
  cursor: pointer;
  /* width: 100px; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-decoration: none;
}

.profile-card-edit-btn:hover {
  background-color: var(--primary-color);
  color: white;
}

.save-btn-position {
  display: flex;
  flex-direction: row;
  justify-content: end;
}

.profile-label {
  color: #848484;
  font-weight: 500;
  font-size: 16px;
}

.profile-input {
  height: 45px;
  border-radius: 10px !important;
  background: var(--bg-light-color) !important;
  border: 1px solid #e9e9e9 !important;
}

.profile-input:disabled {
  background: #f1f0f0 !important;
}

.profile-input:focus {
  box-shadow: none !important;
}

.billing-card {
  border-radius: 20px;
  border: 1px solid #e9e9e9;
  background: var(--bg-light-color);
  padding: 25px;
}

.card-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.billing-card-type {
  font-weight: 600;
}

.remove-card {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  border: none;
  background-color: white;
  box-shadow: 10px 10px 60px 0px rgba(0, 0, 0, 0.06);
}

.card-number {
  font-weight: 600;
  font-size: 0.9rem;
  margin-left: 10px;
}

.card-expiry {
  color: gray;
  font-size: 0.9rem;
  font-weight: 600;
  margin-top: 5px;
}

.add-new-billing-card {
  text-align: center;
  border: 1px dashed silver;
  border-radius: 20px;
  padding: 34px;
}

.add-card-icon {
  margin-bottom: 5px;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  color: var(--primary-color);
  background-color: var(--primary-bg-color);
  border: none;
}

.profile-table-container {
}

.profile-table-container table {
  /* border-collapse: collapse; */
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 12px;
  text-align: center;
  vertical-align: middle;
}

.profile-table-container th,
.profile-table-container td {
  padding: 10px 0;
  /* border: 1px solid #ddd; */
  text-align: left;
  text-align: center;
  vertical-align: middle;
  font-size: 15px;
  font-weight: 600;
  box-sizing: border-box;
}

.profile-table-container th {
  color: rgba(117, 114, 148, 0.4);
  letter-spacing: 1.38px;
}

/* tbody tr:nth-child(even) {
  background-color: #f9f9f9;
} */

.profile-table-container tbody {
  color: #757294;
}

.profile-table-container tbody tr {
  border-radius: 20px !important;
  overflow: hidden;
  background: var(--bg-light-color);
}

.profile-service-img {
  border-radius: 15px;
  width: 70px;
  height: 70px;
  object-fit: cover;
}
