.my-services-page {
  padding: 40px 7%;
  background-color: #f6f6f8;
  min-height: 100vh;
}

.my-services-container {
  border-radius: 20px;
  background: #fff;
  box-shadow: 10px 10px 60px 0px rgba(0, 0, 0, 0.06);
  padding: 30px 25px;
}

.services-search-box {
  border-radius: 10px;
  border: 1px solid #e9e9e9;
  background: var(--bg-light-color);
  padding: 0 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 45px;
}

@media screen and (max-width: 767px) {
  .services-search-box {
    width: 100%;
    margin-bottom: 17px;
  }
}

.services-search-input {
  background-color: transparent;
  border: none;
  margin-left: 10px;
  width: 100%;
}

.services-search-input:focus {
  outline: none;
}

.services-search-input::placeholder {
  color: rgba(0, 6, 33, 0.33);
}

.sort-by-text {
  color: #848484;
  font-size: 15px;
  font-weight: 500;
  min-width: 50px;
}

.sort-by-options {
  border-radius: 10px;
  border: 1px solid #e9e9e9;
  background: var(--bg-light-color);
  padding: 0 15px;
  height: 45px;
}

.add-services-btn {
  border-radius: 10px;
  border: 1px solid var(--primary-color);
  height: 45px;
  padding: 0 15px;
  color: var(--primary-color);
  font-weight: bold;
  cursor: pointer;
}

.services-cards-div {
  margin-top: 30px;
  text-align: center;
}

.service-container {
  cursor: pointer;
  width: 100%;
  position: relative;
}

.service-container-image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 15px;
  height: 260px;
  position: relative;
  /* border-radius: 15px;
  height: 260px;
  width: 100%;
  object-fit: cover; */
}

.sort-div {
  border: 1px solid #e9e9e9;
  border-radius: 10px;
  min-width: 120px;
}

@media screen and (max-width: 767px) {
  .sort-div {
    width: 100%;
  }
}

.sort-input {
  border-radius: 10px !important;
  background-color: var(--bg-light-color);
  height: 45px !important;
  font-size: 13px !important;
  color: #1c1c1c !important;
  line-height: 45px !important;
}

.edit-supplier-icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: absolute;
  right: 60px;
  top: 10px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: rgba(255, 255, 255, 0.12);
  box-shadow: 10px 10px 20px 0px rgba(0, 0, 0, 0.06);
  backdrop-filter: blur(2px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.delete-supplier-icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: absolute;
  right: 10px;
  top: 10px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: rgba(255, 255, 255, 0.12);
  box-shadow: 10px 10px 20px 0px rgba(0, 0, 0, 0.06);
  backdrop-filter: blur(2px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}