.add-info-modal {
  max-width: 60% !important;
  margin: 50px auto !important;
  border: none !important;
}

@media screen and (max-width: 768px) {
  .add-info-modal {
    max-width: 95% !important;
    margin: 20px auto !important;
  }
}

.modal-content {
  background-color: #f6f6f8 !important;
}

.add-info {
  background-color: var(--primary-color) !important;
  border: 1px solid var(--primary-color) !important;
  padding: 10px 30px !important;
  border-radius: 8px !important;
  font-weight: 600 !important;
}

@media screen and (max-width: 768px) {
  .add-info {
    width: 100%;
  }
}

.add-info:hover {
  background-color: var(--primary-hover-color) !important;
  border: 1px solid var(--primary-hover-color) !important;
}

.modal-body {
}

.add-info-body {
  padding: 20px;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 10px 10px 60px 0px rgba(0, 0, 0, 0.06);
}

.min-max {
  margin-top: 10px;
  justify-content: space-between;
}

.add-time-range {
  border: 1.5px solid var(--primary-color);
  border-radius: 10px;
  padding: 15px 20px;
  color: var(--primary-color);
  font-weight: bold;
  cursor: pointer;
  height: 55px;
  align-items: center;
}

.add-time-range:hover {
  background-color: var(--primary-color);
  color: white;
}

.detailed-info {
  font-weight: bold;
  font-size: 1.1rem;
  margin-bottom: 15px;
}

.modalTitle {
  font-size: 24px;
  font-weight: 600;
}

.date-range-input-div {
  background-color: #f6f6f8 !important;
  /* box-shadow: none !important; */
  /* font-size: 0.8rem !important; */
  border-color: #e9e9e9 !important;
  width: 150px !important;
  height: 55px !important;
  border-radius: 10px !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

@media screen and (max-width: 768px) {
  .date-range-input-div {
    width: 50% !important;
  }
}

.time-label {
  color: #767676;
  font-size: 13px;
  font-weight: 700;
}

.time-inputs {
  color: #838383 !important;
  font-size: 13px !important;
}

.modal-label {
  font-size: 15px;
  color: #848484;
  font-weight: 500;
}

.max-budget {
  background-color: var(--bg-light-color);
  height: 50px;
  border-radius: 10px;
  border-color: #e9e9e9;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  padding: 0 10px;
  font-size: 20px;
  font-weight: 700;
  border: 1px solid #e9e9e9;
}

.max-budget input {
  font-size: 20px;
  font-weight: 700;
  border: none;
  background-color: transparent;
  width: 100%;
}

.max-budget input:focus {
  outline: none;
}

.max-budget:focus {
  box-shadow: none !important;
}

.info-text-area {
  background-color: var(--bg-light-color) !important;
  border-radius: 10px !important;
  border-color: #e9e9e9 !important;
}

.info-text-area:focus {
  box-shadow: none !important;
}
