.embla {
  overflow: hidden;
}
.embla__container {
  display: flex;
}
.suppliers .embla__slide {
  flex: 0 0 25%;
  min-width: 0;
}

@media screen and (max-width: 1400px) {
  .suppliers .embla__slide {
    flex: 0 0 33.33%;
  }

  .no-match-parent-card {
    flex: 0 0 33.33%;
  }
}

@media screen and (max-width: 1024px) {
  .suppliers .embla__slide {
    flex: 0 0 50%;
  }

  .no-match-parent-card {
    flex: 0 0 50%;
  }
}

@media screen and (max-width: 600px) {
  .suppliers .embla__slide {
    flex: 0 0 100%;
  }

  .no-match-parent-card {
    flex: 0 0 100%;
  }
}

.landing-suppliers .embla__slide {
  flex: 0 0 33.33%;
  min-width: 0;
}

@media screen and (max-width: 1300px) {
  .landing-suppliers .embla__slide {
    flex: 0 0 50%;
  }
}


@media screen and (max-width: 1000px) {
  .landing-suppliers .embla__slide {
    flex: 0 0 100%;
  }
}

.slider-container1 .embla__slide {
  flex: 0 0 33.33%;
  min-width: 0;
}

@media screen and (max-width: 1200px) {
  .slider-container1 .embla__slide {
    flex: 0 0 50%;
  }
}

@media screen and (max-width: 900px) {
  .slider-container1 .embla__slide {
    flex: 0 0 100%;
  }
}

.event-carousel .embla__slide {
  flex: 0 0 25%;
  min-width: 0;
}

@media screen and (max-width: 1500px) {
  .event-carousel .embla__slide {
    flex: 0 0 33.33%;
  }
}

@media screen and (max-width: 1130px) {
  .event-carousel .embla__slide {
    flex: 0 0 50%;
  }
}

@media screen and (max-width: 768px) {
  .event-carousel .embla__slide {
    flex: 0 0 100%;
  }
}

.slider-container .embla__slide {
  flex: 0 0 33.33%;
  min-width: 0;
}

@media screen and (max-width: 1440px) {
  .slider-container .embla__slide {
    flex: 0 0 50%;
  }
}

@media screen and (max-width: 1024px) {
  .slider-container .embla__slide {
    flex: 0 0 100%;
  }
}

.landing-service-container .embla__slide {
  flex: 0 0 10%;
  min-width: 0;
}

@media screen and (max-width: 1700px) {
  .landing-service-container .embla__slide {
    flex: 0 0 11.11%;
  }
}

@media screen and (max-width: 1500px) {
  .landing-service-container .embla__slide {
    flex: 0 0 14.285%;
  }
}

@media screen and (max-width: 1150px) {
  .landing-service-container .embla__slide {
    flex: 0 0 16.66%;
  }
}

@media screen and (max-width: 950px) {
  .landing-service-container .embla__slide {
    flex: 0 0 20%;
  }
}

@media screen and (max-width: 800px) {
  .landing-service-container .embla__slide {
    flex: 0 0 25%;
  }
}

@media screen and (max-width: 600px) {
  .landing-service-container .embla__slide {
    flex: 0 0 33.33%;
  }
}

@media screen and (max-width: 450px) {
  .landing-service-container .embla__slide {
    flex: 0 0 50%;
  }
}

@media screen and (max-width: 300px) {
  .landing-service-container .embla__slide {
    flex: 0 0 100%;
  }
}