.book-event {
  padding: 35px 7%;
  background-color: #f6f6f8;
}

.billing-info {
  border-radius: 10px;
  border: 1px solid var(--Stroke, #dfe4ea);
  background: var(--White-White, #fff);
  box-shadow: 0px 10px 20px 0px rgba(92, 115, 160, 0.07);
  padding: 25px;
}

.billing-info-name {
  font-size: 1.5rem;
  font-weight: 600;
  cursor: pointer;
}

.billing-info-header {
  font-size: 1.2rem;
  font-weight: bold;
}

.billing-info-subheader {
  font-size: 1.1rem;
  /* font-weight: 600; */
  color: #757294;
}

.billing-info-input {
  width: 100%;
  border-radius: 10px;
  border: 1px solid #e9e9e9;
  background: var(--bg-light-color);
  padding: 12px;
}

.form-check-input {
  border: 2px solid #767676 !important;
  width: 18px !important;
  height: 18px !important;
}

.form-check-input:checked {
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}

.form-check-input:focus {
  box-shadow: none !important;
}

.event-name-header {
  align-items: center;
  border-radius: 10px;
  border: 1px solid #e9e9e9;
  background: var(--bg-light-color);
  padding: 10px 20px;
  font-weight: 600;
}

.event-name-rows {
  align-items: center;
  padding: 10px 20px;
}

.bold-price {
  font-weight: 600;
  color: black;
}

.choose-text {
  color: #848484;
  margin: 15px 0;
}

.service-img-div {
  width: 42px;
  height: 42px;
  border-radius: 3px;
  background: #ededed;
  margin-right: 12px;
}
