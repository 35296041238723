.backgroundStyle {
  background-image: url("../../assests/images/bg.svg"),
    linear-gradient(to top, #dbdedf, #ffffff);
  background-repeat: no-repeat;
  background-position: left 0;
  background-size: 50% 100%;
  position: relative;
  /* background-image: url("../../assests/images/landing_image.png"),
    linear-gradient(to top, #dbdedf, #ffffff); 
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;*/
  /* height: 100vh; */
  /* display: flex;
  flex-direction: column; */
}

.backgroundStyle::before {
  content: ""; /* Essential for pseudo-elements */
  position: absolute;
  top: 0;
  right: 0; /* Place in the right half */
  width: 100%;
  height: 100%;
  background-image: url("../../assests/images/bg.svg"),
    linear-gradient(to top, #dbdedf, #ffffff);
  background-repeat: no-repeat;
  background-size: 50% 100%;
  transform: scaleX(-1);
  z-index: -1;
}

.navbar-div {
  height: 10vh;
}

.landing-bg {
  background-image: url("../../assests/images/landing-images/bg.svg");
  background-size: 100%; /* Decrease width */
  background-repeat: no-repeat;
  background-position: top; /* Move image up */
}

@media screen and (max-width: 1380px) {
  .landing-bg {
    background-image: none;
    background-color: var(--primary-bg-color);
  }
}

.landing-page {
  padding: 50px 7%;
  /* position: relative; */
  /* min-height: 100vh; */
  /* height: 84vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; */
}

.tagline {
  font-size: 36px;
  font-weight: 600;
}

@media screen and (max-width: 767px) {
  .tagline {
    font-size: 25px;
  }
}

@media screen and (max-width: 1024px) {
  .landing-page {
    height: 100%;
    display: block;
  }
}

.event-btn {
  background-color: var(--primary-color) !important;
  color: white !important;
  font-size: 1.1rem !important;
  font-weight: 600 !important;
  padding: 10px 20px !important;
  border-radius: 10px !important;
  width: 180px;
}

.event-btn:hover {
  background-color: var(--primary-hover-color) !important;
}

.event-btn:disabled {
  opacity: 0.5 !important;
  cursor: not-allowed;
}

.tagline-info {
  text-align: center;
  margin-top: 100px;
  border-radius: 20px;
  padding: 40px 60px;
  background-color: var(--primary-bg-color);
}

.big-text {
  font-size: 34px;
  font-weight: 600;
}

.normal-text {
  color: #111928;
  font-size: 24px;
  font-weight: 600;
}

.small-text {
  margin-top: 10px;
  color: rgba(17, 25, 40, 0.84);
  font-size: 16px;
}

@media screen and (max-width: 767px) {
  .tagline-info {
    margin-top: 100px;
    padding: 30px;
  }

  .big-text {
    font-size: 22px;
  }

  .normal-text {
    font-size: 18px;
  }
}

.half-div-text {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.landing-page .timeline-text {
  margin: 100px 100px 35px;
  text-align: center;
}

@media screen and (max-width: 767px) {
  .landing-page .timeline-text {
    margin: 100px 0 35px;
  }
}

.about-timeline {
  margin-top: 70px;
  text-align: center;
}

.landing-img {
  min-width: 60%;
  max-width: 100%;
}

@media screen and (max-width: 575px) {
  .landing-img {
    width: 100%;
  }
}

.landing-page-cards {
  border-radius: 20px;
  background: var(--primary-bg-color);
  display: flex;
  min-height: 350px;
  padding: 40px;
  flex-direction: column;
  align-items: center;
  gap: 30px;
}

.landing-card-icon {
  width: 70px;
  height: 70px;
  background: var(--primary-color);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.landing-card-text {
  color: #111928;
  font-size: 20px;
  font-weight: 600;
}

.landing-card-subtext {
  color: rgba(17, 25, 40, 0.7);
  text-align: center;
  font-size: 16px;
  font-weight: 400;
}

.timeline-last {
  padding: 70px;
  margin-top: 70px;
  text-align: center;
  border-radius: 20px;
  background: var(--bg-light-color);
}

@media screen and (max-width: 767px) {
  .timeline-last {
    padding: 30px;
  }
}

.landing-service-card {
  padding: 10px 10px 12px 10px;
  background-color: transparent;
  border-radius: 15px 15px 0 0;
  /* box-shadow: 0 0px 5px rgba(0, 0, 0, 0.06); */
  cursor: pointer;
  position: relative;
}

.landing-sub-card {
  padding: 15px 10px;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 0px 5px rgba(0, 0, 0, 0.06);
  /* border: 1px solid #E9E9E9; */
  cursor: pointer;
  transition: height 0.3s;
}

.landing-service-card.active {
  background-color: #fff;
  /* z-index: 2; */
  /* box-shadow: 0 0 5px rgba(0, 0, 0, 0.01); */
}

.landing-service-card.active::before {
  content: "";
  position: absolute;
  background-color: transparent;
  left: -30px;
  bottom: 0;
  height: 15px;
  width: 30px;
  border-bottom-right-radius: 15px;
  box-shadow: 15px 0 0 0 #fff;
}

.landing-service-card.active::after {
  content: "";
  position: absolute;
  background-color: transparent;
  right: -30px;
  bottom: 0;
  height: 15px;
  width: 30px;
  border-bottom-left-radius: 15px;
  box-shadow: -15px 0 0 0 #fff;
  /* overflow: auto; */
  /* z-index: 3; */
}

.landing-sub-card.active {
  background-color: #f6f6f8;
  border: 1px solid #e9e9e9;
  box-shadow: none;
  /* height: 380px;
  border-radius: 15px 15px 0 0; */
}

.landing-icon-bg {
  background-color: var(--primary-color);
  height: 40px;
  width: 40px;
  margin: auto;
  border-radius: 50%;
  /* line-height: 50px; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.landing-service-name {
  color: #848484;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.landing-service-flexbox {
  margin-top: 80px;
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: center;
  /* width: 100%; */
}

.landing-service-categories {
  margin-top: 80px;
  /* display: flex;
  flex-direction: row; */
  /* gap: 8px; */
}

.filters-btn {
  display: flex;
  flex-direction: row;
  height: 55px;
  padding: 16px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
}

.landing-suppliers {
  padding: 20px;
  background-color: white;
  border-radius: 20px;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.06);
}

.landing-request-event-div {
  padding-right: 10px;
}

@media screen and (max-width: 767px) {
  .landing-request-event-div {
    padding: 0 10px;
  }
}

.normal-flexbox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition: 0.5s ease-in-out;
}

.supplier-card .owl-dots {
  position: relative;
  top: -85px !important;
  z-index: 2 !important;
}

.supplier-card .owl-nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* position: absolute */
}

.supplier-card .owl-next,
.supplier-card .owl-prev {
  font-size: 30px !important;
  color: var(--primary-color) !important;
  background-color: white !important;
  box-shadow: 10px 10px 60px 0px rgba(0, 0, 0, 0.06) !important;
  width: 43px !important;
  height: 43px !important;
  line-height: 43px !important;
  border-radius: 50% !important;
  /* display: flex !important;
  justify-content: center !important;
  align-items: center !important; */
}

.supplier-card .owl-nav span {
  position: relative;
  bottom: 3px !important;
}

.supplier-card .owl-next {
  right: 5px;
  position: relative;
  bottom: 165px;
}

.supplier-card .owl-prev {
  left: 5px;
  position: relative;
  bottom: 165px;
}

.chatbot-modal {
  position: fixed !important;
  bottom: 0 !important;
  right: 0 !important;
  margin: 15px !important;
}

.empty-image {
  border-radius: 15px;
  height: 260px;
  background-color: black;
}

.video-react {
  border-radius: 15px !important;
  overflow: hidden !important;
}
