.add-new-service-modal {
  max-width: 86% !important;
  margin: 50px auto !important;
  border: none !important;
}

@media screen and (max-width: 768px) {
  .add-new-service-modal {
    max-width: 95% !important;
    margin: 20px auto !important;
  }
}

.service-mui-select-div {
  border-radius: 10px;
  border: 1px solid #e9e9e9;
  background: #ffffff;
  width: 100%;
  height: 45px;
  /* padding: 0 15px; */
  /* font-size: 16px; */
  font-weight: 500;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.service-mui-select-input {
  /* font-weight: 500 !important; */
  font-size: 16px !important;
}

.service-inputs {
  border-radius: 10px;
  border: 1px solid #e9e9e9;
  background: #ffffff;
  width: 100%;
  height: 45px;
  padding: 0 15px;
  font-size: 16px;
  /* font-weight: 500; */
}

.service-input-flex {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.service-input-flex input {
  border: none;
  background-color: transparent;
  font-size: 16px;
  width: 100%;
}


.service-inputs::placeholder,
.info-text-area::placeholder,
.service-input-flex input::placeholder {
  color: rgba(0, 6, 33, 0.33) !important;
  font-weight: 400;
}

.service-inputs:disabled {
  background: #e9ecef;
}

.service-inputs:focus,
.service-input-flex input:focus {
  outline: none;
}

.service-checkbox-div {
  border-radius: 10px;
  background: var(--bg-light-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 67px;
}

.service-checkbox-div.checked {
  background: var(--primary-bg-color);
}

.service-checkbox-text {
  font-size: 13px;
  font-weight: 700;
  color: #767676;
}

.service-checkbox-text.checked {
  color: var(--primary-color);
}

.service-mui-input-div {
  background-color: #ffffff !important;
  /* box-shadow: none !important; */
  /* font-size: 0.8rem !important; */
  border-color: #e9e9e9 !important;
  width: 150px !important;
  height: 55px !important;
  border-radius: 10px !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.service-check-label {
  color: #757294;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 3px;
}

.no-brainer-subtext {
  color: rgba(0, 0, 0, 0.4);
  font-size: 14px;
}

.service-upload-div {
  display: flex;
  height: 117px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  border: 2px dashed #d5d5d5;
  gap: 5px;
  cursor: pointer;
}

.service-attachment-div {
  margin-top: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  overflow-y: auto;
  padding: 10px 0;
}

.service-attachment-pic {
  width: 65px;
  height: 65px;
  flex-shrink: 0;
  border-radius: 10px;
  background: rgba(73, 221, 131, 0.18);
  position: relative;
}

.service-image-cross {
  position: absolute;
  top: -5px;
  right: 5px;
}

.question {
  margin-bottom: 5px; /* Add space between question and answer */
  }

.answer {
  margin-top: 5px; /* Add space above answer */
}

.info-text-area {
  background: #ffffff !important;
}