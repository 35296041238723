* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* body{
  background: #f6f6f8;
} */

/* In your CSS file */
/* Define scrollbar styles */
::-webkit-scrollbar {
  width: 10px; /* Width of the scrollbar */
  height: 10px;
}

/* Track styles */
::-webkit-scrollbar-track {
  background: #f3f3f5; /* Color of the scrollbar track */
  border-radius: 5px;
}

/* Handle styles */
::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: #d9d9d9; /* Color of the scrollbar handle */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #b7b7b7; /* Color of the scrollbar handle on hover */
}

.clickable {
  cursor: pointer;
}

/* .App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
} */

._WuQ0f:nth-child(n + 2),
._3lLk3:nth-child(n + 2) {
  display: none !important;
}

._WuQ0f:first-child {
  text-align: left;
  padding-left: 15px;
}

._2eZzQ {
  display: none;
}

.timeline-gantt-chart {
  /* margin: auto;
  max-width: 100%;  */
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.timeline-btn-div {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 19px;
  padding: 35px 10px 33px 0;
}

.timeline-button {
  height: 30px;
  width: 30px;
  border-radius: 50px;
  border: none;
  font-weight: 500;
  background-color: var(--primary-color);
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.timeline-button:disabled {
  cursor: not-allowed;
  opacity: 0.4;
}

.calendarTop {
  /* font-size: 20px; */
  display: none;
}

/* ._WuQ0f{
  border: none !important;
} */
/* ._2k9Ys{
    width: 100%;
} */

.gantt-div {
  /* width: 100%; */
  overflow-x: auto;
}

._3eULf {
  border: 2px solid rgb(239, 239, 239);
  border-radius: 10px;
  overflow: auto;
  background-color: var(--bg-light-color) !important;
  /* width: 100% !important; */
}

/* ._CZjuD{
  flex: 1;
  width: 100%;
} */

._3_ygE {
  border-top: none !important;
  border-left: none !important;
  background-color: var(--bg-light-color) !important;
}

._3ZbQT {
  border-bottom: none !important;
  border-left: none !important;
  background-color: var(--bg-light-color) !important;
}

._3zRJQ {
  display: none;
}

._35nLX,
._2dZTy,
.today {
  fill: var(--bg-light-color) !important;
}

._31ERP {
  fill: var(--bg-light-color) !important;
}

rect {
  /* stroke-width: 0.3px; */
  /* stroke: none !important; */
}

.dropdown-item:active {
  background-color: var(--bg-light-color) !important;
  /* color: black !important; */
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* width: 90%; */
}

.slick-arrow.slick-next {
}

/* .slick-arrow.slick-next::before, .slick-arrow.slick-prev::before {
  color: white;
  background-color: var(--primary-hover-color) !important;
  border-radius: 50%;
  padding: 0;
  margin: 0;
} */
