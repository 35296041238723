.modal-detailed-info-box {
  border-radius: 10px;
  border: 1px solid #e9e9e9;
  background: var(--bg-light-color);
  padding: 15px;
}

.addRowBtn {
  width: 25px;
  height: 25px;
  border: none;
  background-color: var(--primary-color);
  color: white;
  border-radius: 50%;
  font-size: 15px;
}

.nameRowHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.delOfferBtn {
  width: 28px;
  height: 28px;
  border-radius: 50% !important;
  border: none;
  background-color: #dc3545;
  color: white;
}

.delOfferBtn:hover {
  background-color: #b91324;
}

.offer-table-input {
  width: 50px;
  background-color: transparent;
  border: none;
  /* max-width: 100%; */
}

.offer-table-input:focus{
  outline: none;
}
