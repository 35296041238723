.footer {
  /* height: 6vh; */
  padding: 10px 7%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--primary-color);
  /* line-height: 6vh; */
  font-size: 0.9rem;
  color: white;
}

.footer-left {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 25px;
}

/* .footer-subdiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 25px;
} */

.footer a {
  color: white;
  text-decoration: none;
}

@media screen and (max-width: 950px) {
  .footer {
    display: block;
    height: 100%;
    text-align: center;
    padding: 20px 7%;
  }

  .footer-left {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 650px) {
  .footer-left {
    display: block;
  }

  .footer-left div{
    margin-bottom: 5px;
  }
}
