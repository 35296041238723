.event-tag {
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
  font-weight: 600;
  padding: 8px 20px;
  letter-spacing: 3px;
  border-radius: 20px;
  font-size: 15px;
  background-color: var(--primary-bg-color);
}

.event-box {
  padding: 20px;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0 0px 15px rgba(0, 0, 0, 0.1);
}

.input-boxes {
  width: 14.28%;
}

.event-timeline {
  margin-top: 25px;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0 0px 15px rgba(0, 0, 0, 0.1);
}

.event-timeline-btn {
  color: var(--primary-color);
  font-weight: bold;
  font-size: 1rem;
  cursor: pointer;
  padding: 20px;
  transition: "0.5s";
}

/* .event-btn {
    background-color: var(--primary-color);
    color: black;
  } */

.event-page {
  padding: 40px 7%;
  position: relative;
  background-color: #f6f6f8;
  min-height: 100vh;
}

.suppliers {
  /* top: 800px; */
  /* margin-top: -65px; */
  padding: 20px;
  background-color: white;
  border-radius: 20px;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.06);

  /* position: absolute; */
  /* width: 86%; */
}

.supplier-card {
  margin: 0 10px;
  /* z-index: 0; */
  cursor: pointer;
  position: relative;
}

/* @media screen and (max-width: 768px) {
  .suppliers {
    margin-top: 40px;
  }
} */

.supplier-image {
  border-radius: 15px;
  height: 280px;
  object-fit: cover;
}

.supplier-card .supplier-text {
  padding: 10px 0;
}

.pricing {
  justify-content: space-between;
  align-items: center;
  background-color: #f6f6f8;
  padding: 5px 15px;
  border-radius: 10px;
}

.heading {
  font-size: 30px;
  font-weight: 600;
  text-align: left;
  margin-bottom: 10px;
  margin-left: 12px;
}

.services {
  margin-top: 30px;
  /* position: relative; */
}

.service-card {
  padding: 13px 13px 25px 13px;
  background-color: transparent;
  border-radius: 15px 15px 0 0;
  /* box-shadow: 0 0px 5px rgba(0, 0, 0, 0.06); */
  cursor: pointer;
  position: relative;
}

.sub-card {
  padding: 34px 10px;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 0px 5px rgba(0, 0, 0, 0.06);
  /* border: 1px solid #E9E9E9; */
  cursor: pointer;
  transition: height 0.3s;
}

.selected-img {
  background-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.54) 0%,
      rgba(0, 0, 0, 0.54) 100%
    ),
    url("../../assests/images/service_img3.jpeg");
  background-size: cover;
  background-position: center;
}

.service-card.active {
  background-color: #fff;
  /* z-index: 2; */
  /* box-shadow: 0 0 5px rgba(0, 0, 0, 0.01); */
}

.service-card.active::before {
  content: "";
  position: absolute;
  background-color: transparent;
  left: -30px;
  bottom: 0;
  height: 15px;
  width: 30px;
  border-bottom-right-radius: 15px;
  box-shadow: 15px 0 0 0 #fff;
}

.service-card.active::after {
  content: "";
  position: absolute;
  background-color: transparent;
  right: -30px;
  bottom: 0;
  height: 15px;
  width: 30px;
  border-bottom-left-radius: 15px;
  box-shadow: -15px 0 0 0 #fff;
  /* overflow: auto; */
  /* z-index: 3; */
}

.sub-card.active {
  background-color: #f6f6f8;
  border: 1px solid #e9e9e9;
  box-shadow: none;
  /* height: 380px;
  border-radius: 15px 15px 0 0; */
}

@media screen and (max-width: 768px) {
  .service-card {
    margin: 0 10px;
    /* padding: 13px; 
    border-radius: 15px; */
  }

  /* .service-card::before,
  .service-card::after {
    display: none; 
  } */
}

/* #div1:hover {
  height: 100px; 
  border-bottom: none;
} */

/* .service-card:hover ~ .suppliers {
  top: -60px;
} */

.icon-bg {
  background-color: var(--primary-color);
  height: 48px;
  width: 48px;
  margin: auto;
  border-radius: 50%;
  /* line-height: 50px; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.icon {
  color: #fff;
  font-size: 23px;
}

.service-name {
  color: #848484;
  margin-bottom: 10px;
  font-size: 15px;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.service-price {
  font-size: 20px;
  font-weight: 700;
  margin: 10px 0;
}

.service-price-person {
  color: #848484;
  font-size: 15px;
  margin-bottom: 20px;
  font-weight: 500;
}

.addinfo {
  background-color: var(--primary-color);
  color: white;
  border: none;
  font-size: 13px;
  font-weight: 700;
  padding: 10px 15px;
  border-radius: 10px;
}

.addinfo:disabled {
  opacity: 0.4;
  cursor: not-allowed;
}

.request-event-card {
  background-color: #1c1c1c;
  padding: 25px;
  border-radius: 15px;
}

.request-event-price {
  font-size: 20px;
  font-weight: 700;
  margin: 10px 0;
  color: white;
}

.request-event-btn {
  font-size: 13px;
  font-weight: 600;
  border: none;
  padding: 12px 25px;
  border-radius: 10px;
  background-color: var(--primary-color);
  color: #fff;
}

.request-event-btn:disabled {
  opacity: 0.4;
  cursor: not-allowed;
}

.request-event-btn:hover {
  background-color: var(--primary-hover-color);
}

.add-new-service {
  margin-top: 12px;
  border: 1.5px solid var(--primary-color);
  border-radius: 10px;
  padding: 12px;
  color: var(--primary-color);
  font-weight: bold;
  cursor: pointer;
  font-size: 13px;
}

.add-new-service:hover {
  background-color: var(--primary-color);
  color: white;
}

.owl-nav {
  /* display: flex;
  flex-direction: row;
  justify-content: space-between; */
  /* position: absolute */
}

/* .owl-next,
.owl-prev {
  font-size: 2.5rem !important;
  color: var(--primary-color) !important;
  background-color: white !important;
  box-shadow: 10px 10px 60px 0px rgba(0, 0, 0, 0.06) !important;
  width: 45px !important;
  height: 45px !important;
  line-height: 45px !important;
  border-radius: 50% !important;
} */

/* .owl-nav span {
  position: relative;
  bottom: 5px !important;
} */

.service-slider {
  /* margin: 0 50px; */
  /* display: flex;
  flex-direction: row; */
}

.next-arrow-container,
.prev-arrow-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.next-arrow-container {
  /* left: -17px; */
  align-items: flex-start;
}
.prev-arrow-container {
  /* right: -17px; */
  align-items: flex-end;
}

.next-arrow-container button,
.prev-arrow-container button {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: white;
  border: none;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.07);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: var(--primary-color);
}

.next-arrow-container button:disabled,
.prev-arrow-container button:disabled {
  background-color: rgba(255, 255, 255, 0.4);
  color: silver;
  cursor: not-allowed;
}

/* .owl-next {
  right: -60px;
  position: relative;
  bottom: 195px;
}

.owl-prev {
  left: -60px;
  position: relative;
  bottom: 195px;
} */

.floating-input {
  background-color: #f6f6f8 !important;
  border-radius: 8px !important;
  width: 150px !important;
}

/* .floating-label{
  margin-bottom: 10px !important;
} */

.pricing-price {
  font-size: 16px;
  font-weight: 600;
}

.supplier-heading-text {
  color: #1c1c1c;
  font-size: 20px;
  font-weight: 700;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 5px;
}

.supplier-desc-text {
  padding: 0 5px;
  color: #848484;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.supplier-light-text {
  color: #848484;
  font-size: 12px;
  font-weight: 400;
}

.price-vertical-line {
  height: 25px;
  width: 1.5px;
  background-color: #e9e9e9;
  margin: 0px;
}

.chatbot-fixed-icon {
  background-color: var(--primary-color);
  height: 70px;
  width: 70px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  right: 5%;
  bottom: 30px;
  z-index: 2;
  cursor: pointer;
}

.fixed-chat-box {
  width: 500px;
  position: fixed;
  right: 0px;
  bottom: 0px;
  border-radius: 5px;
  z-index: 3;
  box-shadow: 10px 10px 60px 0px rgba(0, 0, 0, 0.06);
}

.chat-header-opt-flex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.close-chatbot-button {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 0.5px solid var(--Stroke, #dfe4ea);
  background: var(--Gray-Gray-2, #f3f4f6);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.supplier-info-icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: absolute;
  right: 60px;
  top: 10px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: rgba(255, 255, 255, 0.12);
  box-shadow: 10px 10px 20px 0px rgba(0, 0, 0, 0.06);
  backdrop-filter: blur(2px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.add-supplier-icon {
  z-index: 1;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: absolute;
  right: 10px;
  top: 10px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: rgba(255, 255, 255, 0.12);
  box-shadow: 10px 10px 20px 0px rgba(0, 0, 0, 0.06);
  backdrop-filter: blur(2px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.no-match-parent-card {
  flex: 0 0 25%;
  min-width: 0;
  padding: 0 10px;
}

.no-match-card {
  border: 1px solid #e9e9e9;
  background-color: var(--bg-light-color);
  border-radius: 15px;
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

/* .supplier-card .owl-dots {
  margin-top: -15px !important;
  z-index: 1;
}

.supplier-card .owl-dots .owl-dot span {
  background-color: red !important;
}

.supplier-card .owl-dots .owl-dot.active span {
  background-color: green !important;
} */
