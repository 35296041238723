.request-tab {
  cursor: pointer;
  text-align: center;
  color: #757294;
  font-weight: 400;
  padding: 5px;
  /* transition: 0.2s ease-in-out; */
  font-size: 16px;
  margin-right: 10px;
}

.request-tab.active {
  border-bottom: 3px solid var(--primary-color);
}

.requests-export-btn {
  border: 1.5px solid var(--primary-color);
  border-radius: 8px;
  padding: 8px 16px;
  color: var(--primary-color);
  font-weight: 700;
  font-size: 16px;
  cursor: pointer;
}
/*   
  .requests-export-btn:hover {
    background-color: var(--primary-color);
    color: white;
  } */

.request-tabs-section {
  align-items: center;
  justify-content: space-between;
}

.requests-table-container {
  border-radius: 20px;
  background: #fff;
  box-shadow: 10px 10px 60px 0px rgba(0, 0, 0, 0.06);
  padding: 10px 20px 20px;
}

.request-table-scroll {
  overflow-x: auto;
}

.requests-table-container table {
  /* border-collapse: collapse; */
  width: 140%;
  border-collapse: separate;
  border-spacing: 0 12px;
  text-align: center;
  vertical-align: middle;
}

.requests-table-container th,
.requests-table-container td {
  padding: 10px 0;
  /* border: 1px solid #ddd; */
  text-align: left;
  text-align: center;
  vertical-align: middle;
  font-size: 12px;
  font-weight: 400;
  box-sizing: border-box;
}

.requests-table-container th {
  color: rgba(117, 114, 148, 0.4);
  letter-spacing: 1.38px;
}

/* tbody tr:nth-child(even) {
  background-color: #f9f9f9;
} */

.requests-table-container tbody {
  color: #757294;
}

.requests-table-container tbody tr {
  border-radius: 20px !important;
  overflow: hidden;
  background: var(--bg-light-color);
}

/* .row-wrapper {
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid #e9e9e9 !important;
  width: 100%;
} */

.remaining-time-text {
  font-size: 13px;
  font-weight: 700;
}

.status-tag {
  display: inline-block;
  border-radius: 15px;
  background: #c092e4;
  padding: 5px 15px;
  color: white;
  font-weight: 500;
}

.table-img {
  border-radius: 50%;
  height: 27px;
  width: 27px;
  object-fit: cover;
}

/* th:first-child, td:first-child {
  position: sticky;
  left: 0;
  z-index: 1;
  background-color: var(--bg-light-color); 
}

th:first-child{
  background-color: #fff; 

} */
